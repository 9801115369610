.profile__img {
  background: url(../../assets/team-members/kiron.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  order: 1;
  justify-self: center;
  width: 250px;
  height: 250px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

.profile__img:hover {
  cursor: not-allowed;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.socialLinks .website {
  background: linear-gradient(45deg, #333333, #626b73);
  box-shadow: 0 4px 30px rgba(63, 65, 67, 0.6);
}

.socialLinks .messenger {
  background: linear-gradient(45deg, #0695ff, #a334fa, #ff6968);
  box-shadow: 0 4px 30px rgba(255, 105, 104, 0.5);
}

.socialLinks .instagram {
  background: linear-gradient(
    45deg,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  box-shadow: 0 4px 30px rgba(120, 64, 190, 0.6);
}

.socialLinks .facebook {
  background: linear-gradient(45deg, #3b5998, #0078d7);
  box-shadow: 0 4px 30px rgba(43, 98, 169, 0.5);
}
